import './App.css';
import React, { useState } from 'react';
import {BrowserRouter as  Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from "./Components/signupComp/Header";
import Progressstep from './Components/signupComp/Progressstep';
import SignupForm from './Components/signupComp/Forms';
import  Signup  from './Components/signupComp/Signup';
import Login from './Components/signupComp/login';
import LoginForm from './Components/signupComp/Loginform';
import Forgot from './Components/signupComp/forgot';
import ForgetForm from './Components/signupComp/forgotForm';
import EmailForgot from './Components/signupComp/forgotEmailForm';
import { Alert } from 'antd';
import Dashboard from './Components/DashboardAsssemble';

function App() {
  
  
  const formItems = [
    { label: "What is your email?", name: "email", rules: [{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email address' }] },
    { label: "What is your first name?", name: "firstName", rules: [{ required: true, message: 'Please enter your first name' }] },
    { label: "What is your last name?", name: "lastName", rules: [{ required: true, message: 'Please enter your last name' }] },
    { label: "What is your mobile number?", name: "phone", rules: [{ required: true, message: 'Please enter your Mobile Number' }, { pattern: /^[0-9]{10}$/, message: 'Please enter 10 digits' }] },
    { label: "What is your business name?", name: "business_name", rules: [{ required: true, message: 'Please enter your business name' }] },
    { label: "What industry does your business belong to?", name: "industry", rules: [{ required: false, message: 'Please enter your business industry' }] },
    {
      label: "Create your password",
      value:'pwdcheck',
      name: "password",
      rules: [
        { required: true, message: 'Please create your password' },
        { min: 8, message: 'Password must have following criteria' },
        // {
        //   pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
        // }
      ]
    },
    { label: "Confirm password", name: "confirmPassword", rules: [{ required: true, message: 'Please confirm your password' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Passwords do not match!'));
      }
    })
  ]
},
  ]; 




  const [currentStep, setCurrentStep] = useState(0);
  const [inputValue, setInputValues] = useState({});
  const [industryValue, setIndustryValue] = useState("");

  const handleFormFinish = (values) => {
  {
    console.log("values *** ", values);
    // const fullName = `${values.firstName} ${values.lastName}`;
    if (currentStep < formItems.length - 1) {
      setCurrentStep(currentStep + 1);
    }
    setInputValues((prevValues) => {
      const firstName = values.firstName || prevValues.firstName || "";
      const lastName = values.lastName || prevValues.lastName || "";
      const fullName = `${firstName} ${lastName}`.trim(); // .trim() to remove any extra spaces
      const allValues = {
          ...prevValues,
          ...values,
          name: fullName,
          type: "Professional",
          industry: industryValue,
      };
      

      console.log("all vlaues ", allValues);
      // delete allValues.firstName;
      //   delete allValues.lastName;
    //   if (currentStep === formItems.length) {
    //     postSignupData(allValues); // Send the combined data to the API
    // } else {
    //     setCurrentStep(currentStep + 1);
    // }
      if(currentStep === 2) {
        postSignupData(allValues);
      }
      // Return the merged values so they are stored in state
      return allValues;
  });    
  }
  }
  const postSignupData = async (data) => {
    try {
        const response = await fetch('https://api.anvillist.com/api/v1/register', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),

        });

      

        console.log('Response in register:', response);

        if (!response.ok) {
          if (response.status === 422) {
            alert("The email has already been taken.");
          }

          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Success:', result);
        window.location.href = '/login';
  
       
       
        // Handle the success case, e.g., show a success message or redirect
    } catch (error) {
        console.error('Error:', error);
        // Handle the error case, e.g., show an error message
    }
};

const handleChildData = (data) => {
  setIndustryValue(data); // Update parent state with data from child
};

  const { itemsToShow, buttonText } = formslice();


  function formslice()
  {
  // Determine which form items to display based on the current step
  let itemsToShow = [];
  let buttonText='Next';
  if (currentStep === 0) {
    // setItemsToShow(formItems[0]);
    itemsToShow = [formItems[0]]; 
  } else if (currentStep === 1) {
    // setItemsToShow(formItems.slice(1,6));
    itemsToShow = formItems.slice(1, 6); 
  } else {
    // setItemsToShow(formItems.slice(6,8));
    itemsToShow = formItems.slice(6, 8); 
    buttonText='Create My Account'
  }
  return {itemsToShow, buttonText};
}

const location = useLocation();
console.log("hab", location);
const shouldRenderHeader = location.pathname !== '/Dashboard';


  return (

  <>
      {shouldRenderHeader && <Header />}
      <Routes>
        <Route  path="/" element={<>
            <Login />
            <LoginForm/>
          </>} />

          <Route  path="/login" element={<>
            <Login />
            <LoginForm/>
          </>} />


          <Route path="/forgot-password" element={<>
            <Forgot email={itemsToShow} />
            <EmailForgot onFinish={handleFormFinish}/>
          </>} />
          

      
          <Route path="/Otp" element={<>
            <Forgot email={itemsToShow} />
            <ForgetForm/>
          </>} />


          <Route path="/signup" element={<>
            <Signup />
            <Progressstep current={currentStep} />
            <SignupForm formItems={itemsToShow} sendDataToParent={handleChildData} onFinish={handleFormFinish} buttonText={buttonText} />
          </>} />

        <Route path="/Dashboard" element={<>
              <div>
              <Dashboard/>
              </div>
              </>} />
      </Routes>
      </>

);
}


export default App;
