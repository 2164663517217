import React, { useEffect, useState } from "react";
import HeaderDash from './HeaderDash';
// import "./BusinessList.css"; // Import the CSS file

const BusinessList = ({ onBackClick , name, startingTab, onInviteClick }) => {
    console.log("name in list", name)
  const [tab, setTab] = useState(startingTab);
  
  useEffect(() => {
    setTab(startingTab); // Update tab if startingTab changes
  }, [startingTab]);

  const businesses = {
    completed: [
      { date: "12-Jun-2024", company: "Seedson Ltd", country: "Canada", industry: "Health care" },
      { date: "12-Jun-2024", company: "Brendy Ltd", country: "Canada", industry: "Telecoms" },
      { date: "12-Jun-2024", company: "Karsen Ltd", country: "Canada", industry: "Finance" },
      { date: "12-Jun-2024", company: "Meech Merch Ltd", country: "Canada", industry: "Oil & Gas" },
    ],
    incomplete: [
      { date: "12-Jun-2024", email: "john.m@seedson.com", status: "Sent" },
      { date: "12-Jun-2024", email: "femi.m@krakspee.com", status: "Started" },
    ],
  };

  const handleTabChange = (tab) => {
    setTab(tab);
  };

  return (
    <>
   { tab === "completed" && <HeaderDash name={name} /> }

    <div>
    <div className="button-group-container">

      <button onClick={onBackClick} className="back-button">Back</button>
      <div className="status-container">
        <button onClick={() => handleTabChange("completed")} className={`tab ${tab === "completed" ? "active" : ""}`}>
          Completed
        </button>
        <span className="divider">|</span>
        <button onClick={() => handleTabChange("incomplete")} className={`tab ${tab === "incomplete" ? "active" : ""}`}>
          Incomplete
        </button>
      </div>
      <button onClick={onInviteClick} className="invite-button">Invite Business</button>
    </div>
    {/* <p className="subtitle">List of businesses yet to complete their assessment.</p> */}

   {tab === "completed" && (
    <div className="totalassess-container">
    <div className="assess-container">
        <table className="assessment-table">
          {/* <thead>
            <tr>
              <th>Date</th>
              <th>Company</th>
              <th>Country</th>
              <th>Industry</th>
              <th></th>
            </tr>
          </thead> */}
          <tbody>
            {businesses.completed.map((business, index) => (
              <tr key={index}>
                <div className='each-cell'>
                <td className="date-cell">{business.date}</td>
                <td className='company-cell'>{business.company}</td>
                <td>{business.country}</td>
                <td>{business.industry}</td>
                <td><button className="view-button">View</button></td>
              </div>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        </div>
      )}

      {tab === "incomplete" && (
        <div className="totalassess-container">
        <div className="assess-container">
        
        <table className="assessment-table">
          <thead>
            <div className="table-header">
            <tr>
              <th>Date sent</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
            </div>
          </thead>
          <tbody>
            {businesses.incomplete.map((business, index) => (
              <tr key={index}>
                <div className='each-cell'>
                <td className="date-cell">{business.date}</td>
                <td className='company-cell'> {business.email}</td>
                <td>{business.status}</td>
                </div>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        </div>
)}
    </div>
    </>
  );
};

export default BusinessList;
