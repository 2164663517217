import React, { useState } from "react";
import SignupForm from "./Forms";
import { useNavigate } from "react-router-dom";
import LoginSuccess from "./loginSuccess";
import Dashboard from "../DashboardAsssemble";

function LoginForm() {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(""); 
  // const [token, setToken] = useState("");// State to track login success
  // const [name, setName]= useState("")
  const navigate = useNavigate();

  function handleLoginFinish(values) {
    console.log("hanish *** ", values);
    postLogin(values);
  };

  const postLogin = async (data) => {
    try {
        const response = await fetch('https://api.anvillist.com/api/v1/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        console.log("login response ", response);

        if (!response.ok) {
          if (response.status === 422) {
            setLoginError("Invalid credentials");
            alert("Invalid credentials");
        } else {
            throw new Error('Network response was not ok');
        }
        return;
        }
        

        const result = await response.json();
        console.log('Success:', result);

        // setToken(result.access_token);
        // setName(result.data.name); 
        // Set login success state to true
        setLoginSuccess(true);
        // console.log('User Name:', name);
        // console.log('access',token)
        navigate('/Dashboard', { state: { result } });

    } catch (error) {
        console.error('Error:', error);
        setLoginError("An error occurred. Please try again.");
        // Handle the error case, e.g., show an error message
    }
  };

  // const handleLogout = () => {
  //   // Reset the login success state to false to re-render the login form
  //   setLoginSuccess(false);
  //   setToken(''); // Clear the token
  // };

  const formItems = [
    { label: "What is your email?", name: "email", rules: [{ required: true, message: 'Please enter your email' },{ type: 'email', message: 'Please enter a valid email address' }] },
    { label: "Password", value:"pwd1", name: "password", rules: [{ required: true, message: 'Please enter your password' }] }
  ];
  
  return (
    <div>
      {/* {loginSuccess ? (
        <Dashboard token={token} name={name}/>
      ) : ( */}
        <SignupForm 
          formItems={formItems}
          onFinish={handleLoginFinish}
          buttonText="Log in"
        />
      {/* )} */}
    </div>
  );
}

export default LoginForm;
