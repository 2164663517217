import React from 'react';

function LoginSuccess({ token, onLogout }) {
    
    const handleLogout = async () => {
        console.log("token ", token);
        try {
            const response = await fetch('https://api.anvillist.com/api/v1/logout', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                const errorData = await response.json(); // Parse error response
                throw new Error(errorData.message || 'Logout failed. Please try again.');
            }

            console.log('Logout successful');
            onLogout();
        } catch (error) {
            console.error('Error:', error);
            alert(`An error occurred during logout. Please try again. Error: ${error.message}`);
        }
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px', background: '#f5f5f5', marginTop: '20px' }}>
            <h1>Welcome to Anvil</h1>
            <p>Login Successful</p>
            <button 
                onClick={handleLogout} 
                style={{ marginTop: '20px', padding: '10px 20px', background: '#f2c41e', color: 'white', fontSize: '16px', cursor: 'pointer' }}
            >
                Logout
            </button>
        </div>
    );
}

export default LoginSuccess;
