import React from 'react';

const AssessmentTable = ({ assessments }) => {
    return (
        <table className="assessment-table">
            <thead>
                <tr>
                    <div className="cell-header">
                    <th>Date</th>
                    <th>Company</th>
                    <th>Country</th>
                    <th>Industry</th>
                    <th></th>
                    </div>
                </tr>
            </thead>
            <tbody>
                {assessments.map((assessment, index) => (
                    <tr key={index}>
                        <div className='each-cell'>
                        <td className="date-cell">{assessment.date}</td>
                        <td className='company-cell'>{assessment.company}</td>
                        <td>{assessment.country}</td>
                        <td>{assessment.industry}</td>
                        <td><button>View</button></td>
                        </div>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default AssessmentTable;
