

import React, { useState } from 'react';
import HeaderDash from './HeaderDash'; // Import your HeaderDash component
import ProjectToolbar from './projectToolbar'; // Import the ProjectToolbar component
import ProjectTable from './ProjectsTable'; // Import the ProjectTable component

const ManageProjects = ({ name }) => {
  const [view, setView] = useState('form'); // 'form' or 'dashboard'

  // Sample project data for the ProjectTable
  const projects = [
    { client: 'Seedson Ltd', title: 'Market Research', dueDate: '12-Jun-2024', price: '2300 CAD', participants: '4 people', level: '50%' },
    { client: 'Brendy Ltd', title: 'Product Launch Plan', dueDate: '15-Jun-2024', price: '5000 CAD', participants: '3 people', level: '30%' },
    { client: 'Karsen Ltd', title: 'Financial Analysis', dueDate: '20-Jun-2024', price: '4000 CAD', participants: '5 people', level: '70%' },
  ];

  // Function to handle form submission and switch to dashboard view
  const handleCreateProject = (e) => {
    e.preventDefault();
    setView('dashboard'); // Switch to the dashboard view
  };

  // Function to handle the Back button (go back to the form view)
  const handleBackClick = () => {
    setView('form');
  };

  // Function to handle the Create new projects button in the toolbar
  const handleCreateProjectClick = () => {
    setView('form'); // Switch back to the form view to create a new project
  };

  return (
    <>
      {/* Render the form or the dashboard based on the 'view' state */}
      {view === 'form' ? (
        <div className="manage-projects-page">
          <div className="header-create">
            <h1>Create projects</h1>
            <p>Create projects and assign them to your team of professionals.</p>
          </div>
          <form className="manage-projects-form" onSubmit={handleCreateProject}>
            <div className="form-group">
              <label>Project title?</label>
              <input type="text" placeholder="Enter project title" />
            </div>
            <div className="form-group">
              <label>Project Owner's email</label>
              <textarea placeholder="Enter project Owner's email"></textarea>
            </div>
            <div className="form-group">
              <label>Project description</label>
              <textarea placeholder="Enter project description"></textarea>
            </div>
            <div className="form-group">
              <label>
                Skills needed <button className="add-skill-button">+</button>
              </label>
              <input type="text" placeholder="Enter required skills" />
            </div>
            <div className="form-group row">
              <div className="inline-labels">
                <span className="due-date">
                  <i className="icon-calendar"></i> Due date
                </span>
                <span className="assign-members">
                  <i className="icon-user"></i> Assign
                </span>
              </div>
              <div className="form-group-full">
                <label>Milestone</label>
                <div className="input-group">
                  <input type="text" placeholder="Enter milestone" />
                </div>
              </div>
            </div>
            <div className="form-grouptask-row">
              <div className="form-group-half">
                <div className="task-input-group">
                  <label>Task</label>
                  <input type="text" className="task-input" placeholder="Enter task" />
                </div>
                <div className="budget-input-group">
                  <label>Budget</label>
                  <input type="text" className="budget-input" placeholder="Enter budget" />
                </div>
              </div>
              <div className="taskbutton-row">
                <button type="button" className="add-task-button">Add new milestone</button>
                <button type="button" className="add-task-button">Add new task</button>
              </div>
            </div>
            <div className="form-group">
              <label>Payment type</label>
              <select>
                <option>Offline</option>
                <option>Online</option>
              </select>
            </div>
            <button type="submit" className="create-project-button">Create project</button>
          </form>
        </div>
      ) : (
        <>
          {/* Dashboard view with ProjectToolbar and ProjectTable */}
          <ProjectToolbar onBackClick={handleBackClick} onCreateProjectClick={handleCreateProjectClick} />
          <div className="totalassess-container">
        <div className="assess-container">
          <ProjectTable projects={projects} />
          </div>
          </div>
        </>
      )}
    </>
  );
};

export default ManageProjects;
