
import React, { useState } from 'react';
import HeaderDash from './HeaderDash'; // Import HeaderDash
import MeetingsTable from './MeetingsTable'; // Import your MeetingsTable component
import MeetingDetail from './MeetingDetails'; // Import MeetingDetail
import SearchBar from './Searchbar'; // Import Searchbar

const BookMeetings = ({ name }) => {
  const meetingsData = [
    { 
      time: '8:00am | 12-Jun-2024', 
      meetingTitle: 'Create a marketing funnel...', 
      company: 'Seedson Ltd', 
      participants: 'Jane Thomas +2', 
      emails: 'jane@example.com', 
      date: '2024-06-12', 
      startTime: '08:00', 
      endTime: '09:00', 
      description: 'Discussing marketing strategies.' 
    },
    { 
      time: '10:00am | 12-Jun-2024', 
      meetingTitle: 'Discuss Q3 sales strategy', 
      company: 'Brendy Ltd', 
      participants: 'John Doe +3', 
      emails: 'john@example.com', 
      date: '2024-06-12', 
      startTime: '10:00', 
      endTime: '11:00', 
      description: 'Q3 Sales Planning' 
    },
    { 
      time: '1:00pm | 12-Jun-2024', 
      meetingTitle: 'Budget review meeting', 
      company: 'Karsen Ltd', 
      participants: 'Alice Green +1', 
      emails: 'alice@example.com', 
      date: '2024-06-12', 
      startTime: '13:00', 
      endTime: '14:00', 
      description: 'Reviewing company budgets.' 
    },
  ];

  const [formData, setFormData] = useState({
    meetingTitle: '',
    emails: '',
    date: '',
    startTime: '',
    endTime: '',
    description: '',
  });

  const [meetings, setMeetings] = useState(meetingsData); // Initialize meetings with sample data
  const [currentView, setCurrentView] = useState('form'); // 'form', 'list', 'detail'
  const [selectedMeeting, setSelectedMeeting] = useState(null); // Store selected meeting for detail view

  // Handle form input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission to add a new meeting
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new meeting object
    const newMeeting = {
      time: `${formData.startTime} | ${new Date(formData.date).toLocaleDateString('en-GB')}`,
      meetingTitle: formData.meetingTitle,
      company: 'Unknown Company', // You can add a field for company or fetch from somewhere else
      participants: formData.emails,
      emails: formData.emails,
      date: formData.date,
      startTime: formData.startTime,
      endTime: formData.endTime,
      description: formData.description,
    };

    // Add form data to meetings list
    setMeetings([...meetings, newMeeting]);

    // Reset form data
    setFormData({
      meetingTitle: '',
      emails: '',
      date: '',
      startTime: '',
      endTime: '',
      description: '',
    });

    // Switch to meeting list view
    setCurrentView('list');
  };

  // Handle viewing a meeting in detail
  const handleViewMeeting = (meeting) => {
    setSelectedMeeting(meeting); // Set the selected meeting
    setCurrentView('detail'); // Switch to detail view
  };

  // Save the updated meeting details
  const saveMeeting = (updatedMeeting) => {
    // Update the meeting in the list by replacing the old meeting with the updated one
    const updatedMeetings = meetings.map((meeting) => 
      meeting === selectedMeeting ? updatedMeeting : meeting
    );

    setMeetings(updatedMeetings); // Update the meetings list
    setSelectedMeeting(null); // Clear the selected meeting after saving
    setCurrentView('list'); // Go back to the list view
  };

  // Go back to the meeting list from the detail view
  const goBackToList = () => {
    setSelectedMeeting(null); // Clear the selected meeting
    setCurrentView('list'); // Switch back to list view
  };

  // Handle creating a new meeting
  const handleNewMeeting = () => {
    setCurrentView('form'); // Show the form for a new meeting
    setSelectedMeeting(null); // Clear any selected meeting
  };

  return (
    <div>
    

      {/* Show the meeting form */}
      {currentView === 'form' && (
        <div className="form-container">
          <div className="imgtotal-container">
            <div className="img-cont">
              <img src="/Images/svgviewer-png-output.png" alt="Anvil Logo" />
            </div>
            <h2 className="form-title">Invite Businesses</h2>
            <p className="form-subtitle">
              Send an invite to clients or partners for a meeting.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="form">
            <label>Meeting title?</label>
            <input
              type="text"
              name="meetingTitle"
              value={formData.meetingTitle}
              onChange={handleChange}
              required
            />
            <label>Add emails separated by commas?</label>
            <input
              type="text"
              name="emails"
              value={formData.emails}
              onChange={handleChange}
              required
            />
            <label>Date of meeting?</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
            <div className="time-container">
              <div className="time-input">
                <label>Meeting start time?</label>
                <input
                  type="text"
                  name="startTime"
                  value={formData.startTime}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="time-input">
                <label>Meeting end time?</label>
                <input
                  type="text"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <label>Meeting description (optional)</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
            ></textarea>
            <button type="submit" className="submit-btn">
              Book meeting
            </button>
          </form>
        </div>
      )}

      {/* Show the list of meetings */}
      {currentView === 'list' && (
        <>
          <SearchBar onBack={handleNewMeeting} onNewMeeting={handleNewMeeting} />
          <div className="totalassess-container">
          <div className="assess-container">
          <MeetingsTable meetings={meetings} onViewMeeting={handleViewMeeting} /> {/* Using MeetingsTable */}
          </div>
          </div>
        </>
      )}

      {/* Show the meeting details */}
      {currentView === 'detail' && selectedMeeting && (
        <MeetingDetail
          meeting={selectedMeeting}
          goBackToList={goBackToList}
          saveMeeting={saveMeeting} // Pass saveMeeting to MeetingDetail
        />
      )}
    </div>
  );
};

export default BookMeetings;
