import React from 'react';

const HeaderDash = ({name}) => {
    return (
        <header className="headerdash">
            <div className="logo">
            <img src="/Images/svgviewer-png-output.png" alt="Anvil Logo" />
            </div>
            <nav className="nav-middle">
                <ul>
                    <li><a href='Dashboard'>Dashboard</a></li>
                    <li><a href='Assessment'>Assessment</a></li>
                    <li><a href='Meetings'>Meetings</a></li>
                    <li><a href='Workforce'>Workforce</a></li>
                </ul>
            </nav>
            <nav className="nav-right">
                <ul>
                    <li><a href="/Business">Business</a></li>
                    <li>|</li>
                    <li><a href={`/${name}`}>{name}</a></li>
                </ul>
             </nav>
        </header>
    );
};

export default HeaderDash;
