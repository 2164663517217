import React, { useState } from "react";
import HeaderDash from './HeaderDash';
// import "./InviteBusinesses.css"; // Import the CSS file

const InviteBusinesses = ({ onSendInvitation, name}) => {
  const [emails, setEmails] = useState([""]);

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
    // onAddEmail();
  };

  const handleSendInvitation = () => {
    // Here you can add your logic for sending invitation
    console.log("Emails to send invitations to:", emails);
    onSendInvitation(); // This will call the parent function to switch to BusinessList
  };

  return (
    <>
    <HeaderDash name={name}/>
    <div className="send-assessment-page">
    <div className="sendheader">
        <img src="/Images/svgviewer-png-output.png" alt="Anvil Logo" />
        <h1 className="sendtitle">Invite Businesses</h1>
        <p className="sendsubtitle">Send an invite to prospective clients to take an assessment.</p>
    </div>
    <div className="email-input-container">
        {emails.map((email, index) => (
            <div key={index} className="email-input-wrapper">
                <label>What is the recipient's email?</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    placeholder="Enter email"
                />
            </div>
        ))}
        {/* {error && <p className="error-message">{error}</p>} */}
        <button onClick={handleAddEmail} className="add-email-button">Add another email +</button>
    </div>
    <button onClick={handleSendInvitation} className="send-invitation-button">Send Invitation</button>
</div>
</>
  );
};
export default InviteBusinesses;
