import React from 'react';

const ProjectsTable = ({ projects }) => {
    return (
        <table className="assessment-table">
            <thead>
                <tr>
                <div className="cell-header">
                    <th>Clients Name</th>
                    <th>Project title</th>
                    <th>Due date</th>
                    <th>Price</th>
                    <th>Participants</th>
                    <th>Level</th>
                    <th></th>
                    </div>
                </tr>
            </thead>
            <tbody>
                {projects.map((project, index) => (
                    <tr key={index}>
                        <div className='each-cell'>
                        <td className='company-cell'>{project.client}</td>
                        <td>{project.title}</td>
                        <td className='due-cell'>{project.dueDate}</td>
                        <td className='price-cell'>{project.price}</td>
                        <td>{project.participants}</td>
                        <td>{project.level}</td>
                        <td><button>View</button></td>
                        </div>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProjectsTable;
